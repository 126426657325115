import React from 'react'
import { CtaContainer, WatchText, ArrowIcon } from './styled/MkSubCtaRectangle.styled'
import { Maybe, MkSubCtaRectangle as MkSubCtaRectangleGQL } from 'graphql/gql/wrapper/graphql'
import { useAmplitude } from '@/utils/hooks/useAmplitude'

export type MkSubCtaRectangleProps = {
  mk_sub_cta_rectangle: MkSubCtaRectangleGQL
  moduleAnalyticsEventProperty?: Maybe<string>
}

const MkSubCtaRectangle: React.FC<MkSubCtaRectangleProps> = ({
  mk_sub_cta_rectangle,
  moduleAnalyticsEventProperty,
}) => {
  const { logAmplitudeEvent } = useAmplitude()

  return (
    <CtaContainer
      href={mk_sub_cta_rectangle?.mk_callToActionLink ?? ''}
      onClick={() => {
        const { callToActionLinkAnalyticsEventName, mk_callToActionLink, mk_callToAction_text } = mk_sub_cta_rectangle

        logAmplitudeEvent(callToActionLinkAnalyticsEventName, mk_callToActionLink, {
          Module: moduleAnalyticsEventProperty,
          'Link Text': mk_callToAction_text,
        })
      }}
    >
      <WatchText>{mk_sub_cta_rectangle?.mk_callToAction_text}</WatchText>
      <ArrowIcon
        src="https://s3.amazonaws.com/cdn.fanduel.com/images/2023/fanduel/homepage/facelift/blue-arrow.svg"
        alt="Arrow"
      />
    </CtaContainer>
  )
}

export default MkSubCtaRectangle
