import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mediaEmotion } from '@/utils/general'
import { MkInfoCardsTemplate } from 'graphql/gql/wrapper/graphql'
import { Container, GridContainer } from './styled/Cards.styled'
import ExploreCard from './ExploreCard'

type GridItemProps = {
  xsm?: number | null
  sm?: number | null
  md?: number | null
  lg?: number | null
  xl?: number | null
}

export const GridItem = styled.div<GridItemProps>`
  ${({ xsm = 12, sm, md, lg, xl }) => css`
    grid-column: span ${xsm};
    ${sm &&
    mediaEmotion.sm(css`
      grid-column: span ${sm};
    `)}
    ${md &&
    mediaEmotion.md(css`
      grid-column: span ${md};
    `)}
    ${lg &&
    mediaEmotion.lg(css`
      grid-column: span ${lg};
    `)}
    ${xl &&
    mediaEmotion.xl(css`
      grid-column: span ${xl};
    `)}
  `}
`

type CardsProps = {
  mk_infoCards: MkInfoCardsTemplate
}

export default function Cards({ mk_infoCards }: Readonly<CardsProps>) {
  return (
    <Container infoCardTemplate={mk_infoCards}>
      <GridContainer>
        {mk_infoCards?.mk_cards?.map((card, index) => (
          <GridItem
            key={index}
            xsm={card?.mk_grid_span_xsm}
            sm={card?.mk_grid_span_sm}
            md={card?.mk_grid_span_md}
            lg={card?.mk_grid_span_lg}
            xl={card?.mk_grid_span_xl}
          >
            <ExploreCard
              mk_infoCard={card}
              moduleAnalyticsEventProperty={mk_infoCards.moduleAnalyticsEventProperty}
              placementAnalyticsEventProperty={mk_infoCards.placementAnalyticsEventProperty}
            />
          </GridItem>
        ))}
      </GridContainer>
    </Container>
  )
}
