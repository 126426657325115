import { MkSubCtaHtp } from "graphql/gql/wrapper/graphql";
import React from "react";
import { AccessibleLink, StyledHeading, StyledImg } from './styled/MkSubHowToPlayCta.styled';

type  MkSubHowToPlayCtaProps = {
  mk_sub_cta_htp: MkSubCtaHtp
}

const MkSubHowToPlayCta: React.FC<MkSubHowToPlayCtaProps> = ({ mk_sub_cta_htp }) => {
  return (
    <StyledHeading>
      <AccessibleLink name={mk_sub_cta_htp.mk_callToAction_text ?? ''} href={mk_sub_cta_htp.mk_callToActionLink ?? ''}>
      {mk_sub_cta_htp?.mk_callToAction_text}
</AccessibleLink>
      <StyledImg 
      src="https://s3.amazonaws.com/cdn.fanduel.com/images/2023/fanduel/homepage/facelift/arrow-right.svg"
      alt="Arrow" />
    </StyledHeading>
  )
}

export default MkSubHowToPlayCta;