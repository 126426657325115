import { ImageProps, StyledLinkProps } from '@/types/cards.d'
import { proximaNova } from '@/utils/fonts'
import styled from '@emotion/styled'
import Image from 'next/image'

export const StyledHeading = styled.h3`
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
  color: rgb(255, 255, 255);
  font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%;
  margin-bottom: 10px;
`
const ImageWrapper = styled.span`
  margin: 0px;
  padding: 0px;
  border-style: none;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  width: 20px;
  height: auto;
  margin-left: 5px;
`


export const StyledImg: React.FC<ImageProps> = ({ src, alt, width = 20, height = 20 }) => (
  <ImageWrapper>
    <Image 
      className="next-image" 
      src={src} 
      alt={alt} 
      width={width} 
      height={height} 
    />
  </ImageWrapper>
)




const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`

export const AccessibleLink: React.FC<StyledLinkProps> = ({ name, ...props }) => (
  <StyledLink {...props} aria-label={name} />
)
