import styled from '@emotion/styled'
import React from 'react'
import { StyledParagraph } from './styled/MkSubBodyContentLove.styled'
import { MkSubBodyContentLove as MkSubBodyContentLoveGQL } from 'graphql/gql/wrapper/graphql'

type MkSubBodyContentProps = {
  mk_sub_body_content_love: MkSubBodyContentLoveGQL
}

const MkSubBodyContentLove: React.FC<MkSubBodyContentProps> = ({ mk_sub_body_content_love }) => {
  return (
    <>
      <StyledParagraph mk_bodyColor={mk_sub_body_content_love.mk_bodyColor}>
        {mk_sub_body_content_love?.mk_body}
      </StyledParagraph>
    </>
  )
}

export default MkSubBodyContentLove
