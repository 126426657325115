import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MkInfoCardsTemplate } from 'graphql/gql/wrapper/graphql'

type MkInfoCardsTemplatePartial = Pick<MkInfoCardsTemplate, 'mk_BackgroundColor'>

type ContainerProps = {
  infoCardTemplate?: MkInfoCardsTemplatePartial
}

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 4px;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem;

  ${mediaEmotion.md(css`
    grid-gap: 10px;
  `)}
`

export const Container = styled.div<ContainerProps>`
  background-color: ${(props) => props.infoCardTemplate?.mk_BackgroundColor?.value || 'unset'};
`