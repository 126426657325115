import { proximaNova } from '@/utils/fonts'
import styled from '@emotion/styled'
import { MkHeading } from 'graphql/gql/wrapper/graphql'

type MkHeadingTemplatePartial = Pick<MkHeading, 'mk_BackgroundColor'>

type WrapperProps = {
  mk_HeadingTemplate?: MkHeadingTemplatePartial
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props) => props.mk_HeadingTemplate?.mk_BackgroundColor?.value || 'unset'};
`
export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 3px;
  max-width: 75rem;
  margin: auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  padding-top: 30px;
`

export const ContentWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  flex: 0 0 auto;
  flex-basis: auto;
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
`

export const Title = styled.h1`
  color: rgb(19, 19, 20);
  font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
  font-size: 32px;
  font-weight: 700;
  line-height: 115%;
  margin-bottom: 15px;
  text-align: center;
`

export const SubTitle = styled.p`
  font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
  color: rgb(19, 19, 20);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 25px;
  text-align: center;
`
