import { Maybe } from 'graphql/gql/orch/graphql'
import MkSubBody from './builderSubComponents/MkSubBody'
import MkSubBodyContentLove from './builderSubComponents/MkSubBodyContentLove'
import MkSubBodyHtp from './builderSubComponents/MkSubBodyHtp'
import CallToAction from './builderSubComponents/MkSubCta'
import MkSubCtaRectangle from './builderSubComponents/MkSubCtaRectangle'
import MkSubHeader from './builderSubComponents/MkSubHeader'
import MkSubHeaderLarge from './builderSubComponents/MkSubHeaderLarge'
import MkSubHowToPlayCta from './builderSubComponents/MkSubHowToPlayCta'

type BuilderSectionProps = {
  details: any
  moduleAnalyticsEventProperty?: Maybe<string>
}

export default function BuilderSection({ details, moduleAnalyticsEventProperty }: Readonly<BuilderSectionProps>) {
  if (details?._type === 'mk_sub_header') {
    return <MkSubHeader mk_subHeader={details} />
  }
  if (details?._type === 'mk_sub_body') {
    return <MkSubBody mk_sub_body={details} />
  }
  if (details?._type === 'mk_sub_cta') {
    return <CallToAction mk_SubCta={details} />
  }
  if (details?._type === 'mk_sub_body_htp') {
    return <MkSubBodyHtp mk_sub_body_htp={details} />
  }
  if (details?._type === 'mk_sub_cta_htp') {
    return <MkSubHowToPlayCta mk_sub_cta_htp={details} />
  }
  if (details?._type === 'mk_sub_cta_rectangle') {
    return (
      <MkSubCtaRectangle mk_sub_cta_rectangle={details} moduleAnalyticsEventProperty={moduleAnalyticsEventProperty} />
    )
  }
  if (details?._type === 'mk_sub_header_large') {
    return <MkSubHeaderLarge mk_sub_header_large={details} />
  }

  if (details?._type === 'mk_sub_body_content_love') {
    return <MkSubBodyContentLove mk_sub_body_content_love={details} />
  }
  return <></>
}
