import SportsBookHeader from './SportsBookHeader/Sportsbook'
import DFS from './Dfs/Dfs'

type HeaderProps = {
  headerToDisplay: string,
}


const Header  = ({headerToDisplay} :HeaderProps) => {
  const headerToDisplayLowerCase = headerToDisplay.toLocaleLowerCase();
  if (headerToDisplayLowerCase === 'sportsbook') {
    return (
        <SportsBookHeader />
    );
  } else if (headerToDisplayLowerCase === 'dfs') {
    return (
        <DFS />
    );
  } else {
    return null;
  }
}

export default Header;
