import { Dispatch, SetStateAction } from 'react'
import styles from './Tabs.module.scss'
import cx from 'classnames'
import { Maybe, MkProductOptions } from 'graphql/gql/wrapper/graphql'

type TabsProps = {
  tabsData: Maybe<MkProductOptions>[]
  currentTab: number
  setCurrentTab: Dispatch<SetStateAction<number>>
}

const Tabs = ({ tabsData, currentTab, setCurrentTab }: TabsProps) => {
  return (
    <div data-testid="tabs" className={styles.container}>
      {tabsData &&
        tabsData.map((product, i) => (
          <button
            key={product?.productName}
            onClick={() => setCurrentTab(i)}
            className={cx(styles.tab, currentTab == i && styles.active)}
            id={`tab${i}`}
            data-testid={currentTab == i ? 'active-tab' : 'inactive-tab'}
          >
            {product?.productName}
          </button>
        ))}
    </div>
  )
}

export default Tabs
