import { MkHeading } from 'graphql/gql/wrapper/graphql'
import { Container, ContentWrapper, SubTitle, Title, Wrapper } from './styled/Heading.stlyed'

type HeadingProps = {
  mk_heading: MkHeading
}

const Heading: React.FC<HeadingProps> = ({ mk_heading }) => {
  return (
    <Wrapper mk_HeadingTemplate={mk_heading}>
      <Container>
        <ContentWrapper>
          <Title>{mk_heading.mk_heading_main}</Title>
          <SubTitle>{mk_heading.mk_heading_sub}</SubTitle>
        </ContentWrapper>
      </Container>
    </Wrapper>
  )
}

export default Heading
