import styles from './SportsbookHeader.module.scss';

export default function SportsBookHeader() {
  return (
    <>
      <header
        className={styles.globalheader}
        data-testid="sportsbook-header"
      >
        <nav
          className={styles.sportsbooknav}
        >
          <ul
            className={styles.sportsbooknavlist}
          >
            <li
              className={styles.sportsbooknavitem}>
              <a
                className={styles.sportsbookanchor}
                href="https://www.fanduel.com/"
                target="_blank"
                rel="noreferrer"
              >
                <span
                 className={styles.sportsbookanchorspan}
                >
                  Fantasy
                </span>
                <i
                  className={styles.newwindowicon}
                >

                </i>
              </a>
            </li>
            <li
              className={styles.sportsbooknavitem}
            >
              <span
               className={styles.sportsbooktitle}
              >
                Sportsbook
              </span>
            </li>
            <li
              className={styles.sportsbooknavitem}
            >
              <span
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "0px",
                  display: "inline-block",
                  fontFamily: "Proxima-Nova",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  letterSpacing: "0px",
                }}
              >
                Horse Racing
              </span>
            </li>
          </ul>
        </nav>
        <div
          className={styles["global-header-container"]}
          style={{
            border: "0px",
            padding: "0px",
            margin: "0px auto",
            display: "flex",
            maxWidth: "100%",
            paddingLeft: "14px",
            paddingRight: "14px",
            background: "rgb(30, 55, 91)",
          }}
        >
          <section
            className={styles["logo-container"]}
            style={{
              margin: "0px",
              border: "0px",
              display: "block",
              padding: "0px 24px 0px 0px",
              position: "relative",
              left: "0px",
              marginLeft: "0px",
              paddingLeft: "0px",
            }}
          >
            <a
              className={styles["header-link"]}
              href="https://sportsbook.fanduel.com/"
              target="_self"
              style={{
                margin: "0px",
                padding: "0px",
                border: "0px",
                backgroundColor: "transparent",
                textDecoration: "none",
                lineHeight: "inherit",
                color: "rgb(23, 121, 186)",
                cursor: "pointer",
                display: "flex",
                height: "66px",
              }}
            >
              <span
                className={styles["logo logo-fanduel"]}
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "0px",
                  backgroundRepeat: "no-repeat",
                  display: "inline-block",
                  textIndent: "-999em",
                  width: "129px",
                  backgroundImage:
                    'url("https://d17odppiik753x.cloudfront.net/images/logos/logo--sportsbook.svg")',
                  height: "35px",
                  marginTop: "15px",
                  backgroundSize: "auto",
                }}
              >
                FanDuel
              </span>
              <span
                className={styles["logo logo-tagline"]}
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "0px",
                  backgroundRepeat: "no-repeat",
                  marginTop: "20px",
                  textIndent: "-999em",
                  display: "none",
                }}
              >
                Sportsbook
              </span>
            </a>
          </section>
          <nav
            className={styles["primary-nav"]}
            style={{
              margin: "0px",
              padding: "0px",
              border: "0px",
              display: "block",
              flex: "0 0 auto",
              WebkitBoxFlex: "0",
            }}
          >
            <a
              className={styles["nav-trigger mobile-only"]}
              href="https://www.fanduel.com/pigskin#"
              style={{
                margin: "0px",
                border: "0px",
                backgroundColor: "transparent",
                cursor: "pointer",
                WebkitTapHighlightColor: "transparent",
                display: "none",
                padding: "0px 20px",
                textDecoration: "none",
                fontSize: "1.5rem",
                color: "rgb(255, 255, 255)",
                lineHeight: "66px",
              }}
            >
              <i
                className={styles.icon}
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "0px",
                  lineHeight: "inherit",
                  fontFamily: "FanDuel",
                  fontStyle: "normal",
                  WebkitFontSmoothing: "antialiased",
                  display: "block",
                  fontWeight: "normal",
                }}
              >
                ☰
              </i>
            </a>
          </nav>
          <section
            className={styles["logged-out-user secondary-nav"]}
            style={{
              margin: "0px",
              padding: "0px",
              border: "0px",
              flex: "1 1 0%",
              fontSize: "0.875rem",
              display: "flex",
              WebkitBoxFlex: "1",
              flexGrow: 1,
              WebkitBoxPack: "end",
              justifyContent: "flex-end",
            }}
          >
            <div
              className={styles["login-menu-block"]}
              style={{
                border: "0px",
                margin: "0px",
                padding: "0px",
                marginRight: "12px",
                lineHeight: "65px",
                verticalAlign: "middle",
                display: "inline-block",
              }}
            >
              <a
                className={styles["button mini transparent-bg"]}
                href="https://account.sportsbook.fanduel.com/login"
                target="_self"
                style={{
                  textDecoration: "none",
                  WebkitTapHighlightColor: "transparent",
                  whiteSpace: "nowrap",
                  margin: "0px",
                  display: "inline-block",
                  textAlign: "center",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  lineHeight: 1.25,
                  appearance: "none",
                  border: "0px",
                  backgroundImage: "none",
                  backgroundColor: "transparent", 
                  borderRadius: "3px",
                  fontSize: "14px",
                  color: "rgb(255, 255, 255)", 
                  boxShadow: "rgb(255, 255, 255) 0px 0px 0px 1px inset",
                  padding: "6px 9px",
                }}
              >
                Log in
              </a>
            </div>
            <div
              className={styles["login-menu-block"]}
              style={{
                border: "0px",
                margin: "0px",
                padding: "0px",
                marginRight: "12px",
                lineHeight: "65px",
                verticalAlign: "middle",
                display: "inline-block",
              }}
            >
              <a
                className={styles["button mini transparent-bg"]}
                href="https://account.sportsbook.fanduel.com/join/select-state"
                target="_self"
                style={{
                  textDecoration: "none",
                  WebkitTapHighlightColor: "transparent",
                  whiteSpace: "nowrap",
                  margin: "0px",
                  display: "inline-block",
                  textAlign: "center",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  lineHeight: 1.25,
                  appearance: "none",
                  border: "0px",
                  backgroundImage: "none",
                  backgroundColor: "rgb(255, 255, 255)",
                  borderRadius: "3px",
                  fontSize: "14px",
                  color: "rgb(30, 55, 91)",
                  boxShadow: "rgb(255, 255, 255) 0px 0px 0px 1px inset",
                  padding: "6px 9px",
                }}
              >
                Join
              </a>
            </div>
          </section>
        </div>
      </header>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  margin: 0px;
  padding: 0px;
  border: 0px;
  height: 100%;
  overflow-y: scroll;
  line-height: 1.15;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-size: 100%;
}

body {

  border: 0px;
  -webkit-tap-highlight-color: rgb(255, 255, 158);
  font-size: 16px;
  width: 100%;
  height: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(10, 10, 10);
  padding: 0px;
  background: rgb(255, 255, 255);
  padding-top: 0px;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  font-family: Proxima-Nova, Helvetica, Arial, sans-serif;
  letter-spacing: 0px;
}
`,
        }}
      />
    </>
  );
}