import { mediaEmotion } from '@/utils/general';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CtaContainer = styled.a`
  display: flex;
  margin: 0px;
  width: 80%;

  text-decoration: none;
  cursor: pointer;
  padding: 14px 12px;
  border-radius: 4px;
  border: 1px solid rgb(0, 112, 235);
  background: rgba(255, 255, 255, 0);
  transition: all 0.3s ease;
  color: rgb(0, 95, 200);
  font-family: ProximaNovaRegular, sans-serif;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  background-color: rgba(255, 255, 255, 0);
  box-shadow: hsla(0, 0%, 0%, 0.1) 0px 2px 4px 0px;
  z-index: 100;
  margin-left: 20px;
  margin-right: 20px;


  ${mediaEmotion.sm(css`
    width: 150px;
    margin-left: unset;
    margin-right: unset;

      &:hover {
      transform: scale(1.1); // Ensure consistent behavior in small viewports
    }
  `)}
`

export const WatchText = styled.span`
  margin-right: 8px;
`

export const ArrowIcon = styled.img`
  padding: 0px;
  border-style: none;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  width: 17px;
  height: auto;
  margin-left: 8px;
`
