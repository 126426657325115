import React from 'react'
import { Footer as BaseFooter, LinkProvider } from '@fanduel/og-fe-library'
import { useNavMenus } from '@/utils/hooks/services/useMenus'
import styles from './Footer.module.scss'
import Link, { LinkProps } from 'next/link'

type StandardLink = typeof Link
const FooterLink = ({ children, href }: LinkProps & { children: React.ReactElement }) => {
  const renderChildren = () =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        href,
      }),
    )
  return <>{renderChildren()}</>
}
function Footer() {
  const { menuData } = useNavMenus()
  const footerMenuData = menuData.footer
  const termsMenuData = menuData.terms && menuData.terms.menuItems
  const partnersMenuData = menuData.copyright && menuData.copyright.menuItems
  const rg = (
    <div>
      {' '}
      Hope is here. GamblingHelpLineMA.org or call 800-327-5050 for 24/7 support MA. Call 1-877-8HOPE-NY or Text HOPENY
      467369 NY. 21+ in select states. FanDuel is offering online sports wagering in Kansas under an agreement with
      Kansas Star Casino, LLC. Gambling Problem? Call 1-800-GAMBLE R or visit http://FanDuel.com/RG (CO, IA, KY, MI, NJ,
      OH, PA, IL, TN, VA), 1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ), 1-888-789-7777 or visit ccpg.org/chat (CT),
      1-800-9-WITH-IT (IN), 1-800-522-4700 or visit http://ksgamblinghelp.com (KS), 1-877-770-STOP (LA), visit
      www.mdgamblinghelp.org (MD), 1-877-8-HOPENY or text HOPENY (467369) (NY), 1-800-522-4700 (WY), or visit
      www.1800gambler.net(WV).
    </div>
  )

  return (
    <LinkProvider CustomLink={FooterLink as StandardLink}>
      <BaseFooter
        footerSitemapLinks={footerMenuData?.menuItems}
        footerSitemapClass={styles.footerSitemapLinks}
        keyLinks={termsMenuData}
        partnerLinks={partnersMenuData}
        rgComponent={rg}
      />
    </LinkProvider>
  )
}

export default Footer
