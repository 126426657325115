import { useEffect, useState } from 'react'
import { numericBreakpoints } from '../general'

export type WindowData = {
  windowExists: boolean
  windowWidth: number
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

const createWindowData = (windowWidth: number) => ({
  windowExists: typeof window !== 'undefined',
  windowWidth,
  isMobile: windowWidth < numericBreakpoints.sm,
  isTablet: windowWidth >= numericBreakpoints.sm && windowWidth < numericBreakpoints.lg,
  isDesktop: windowWidth >= numericBreakpoints.lg,
})

// Tracks window width and whether window is mobile, tablet, or desktop
const useWindow = (): WindowData => {
  const [windowData, setWindowData] = useState({
    windowExists: false,
    windowWidth: 0,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowData(createWindowData(window.innerWidth))

      const handleResize = () => {
        setWindowData(createWindowData(window.innerWidth))
      }
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return windowData
}

export default useWindow
