import { graphql } from '../../gql/wrapper/gql'

export const getPage = graphql(`
  query getPage($mk_slug: String, $isPreview: Boolean) {
    allMkMarketingPage(where: { mk_slug: { current: { eq: $mk_slug } }, _: { is_draft: $isPreview } }) {
      _id
      mk_slug {
        current
      }
      mk_header
      mk_footer
      mk_pageNameAnalyticsEventProperty
      mk_pageBuilder {
        ... on MkCustomHtmlTemplate {
          _type
          mk_html
        }
        ... on MkCustomJsTemplate {
          _type
          mk_js
        }
        ... on MkWhyFanDuelTemplate {
          _type
          subtext
          title
          whyFanduel {
            _type
            html
            title
          }
        }
        ... on MkWhereCanYouPlayTemplate {
          _key
          _type
          products {
            _key
            _type
            detailsUrl
            disclaimerText
            productName
            availableLocations {
              code
              name
              subtext
              tvgOnly
              _key
              _type
            }
          }
        }
        ... on MkHeading {
          _key
          _type
          mk_heading_main
          mk_heading_mainColor {
            value
          }
          mk_heading_sub
          mk_heading_subColor {
            value
          }
          mk_BackgroundColor {
            value
          }
        }
        ... on MkSpacer {
          _type
          title
          backgroundColor {
            value
          }
          defaultSpacing
          spacingBreakpointXsm
          spacingBreakpointSm
          spacingBreakpointMd
          spacingBreakpointLg
          spacingBreakpointXl
          spacingBreakpointXxl
        }
        ... on MkCarouselHero {
          _type
          title
          mobileSlideNavUi
          desktopSlideNavUi
          mobileIntroVideoUrl
          desktopIntroVideoUrl
          moduleAnalyticsEventProperty
          placementAnalyticsEventProperty
          slides {
            title
            isHidden
            mobileBackgroundImage {
              asset {
                url
              }
            }
            tabletBackgroundImage {
              asset {
                url
              }
            }
            desktopBackgroundImage {
              asset {
                url
              }
            }
            productNameAndLogo
            customProductNameAndLogoImage {
              asset {
                url
              }
            }
            headlineImage {
              asset {
                url
              }
            }
            headlineText
            sublineHeading
            sublineDescription
            buttonOneText
            buttonOneUrl
            buttonOneAnalyticsEventName
            buttonOneProductAnalyticsEventProperty
            buttonTwoText
            buttonTwoUrl
            buttonTwoAnalyticsEventName
            buttonTwoProductAnalyticsEventProperty
          }
        }
        ... on MkCallToAction {
          _key
          _type
          mk_backgroundColor {
            value
          }
          mk_callToAction
          mk_backgroundImage {
            asset {
              url
              altText
            }
          }
          mk_callToActionLink
          mk_cards {
            mk_backTextColor {
              value
            }
            bodyTextColor {
              value
            }
            mk_headingText
            mk_headingTextColor {
              value
            }
            mk_sideImage {
              asset {
                altText
                url
              }
            }
            mk_topHtml
          }
          mk_headingh1
          mk_headingh1Color {
            value
          }
          mk_lineUnderHeaderColor {
            value
          }
          mk_topHtml
          separatorImage {
            asset {
              altText
              url
            }
          }
        }
        ... on MkInfoCardsTemplate {
          _key
          _type
          moduleAnalyticsEventProperty
          placementAnalyticsEventProperty
          mk_BackgroundColor {
            value
          }
          mk_cards {
            mk_position
            exploreItemContentMaxWidth
            mk_grid_span_xsm
            mk_grid_span_sm
            mk_grid_span_md
            mk_grid_span_lg
            mk_grid_span_xl
            mk_cardLink
            cardLinkAnalyticsEventName
            cardProductAnalyticsEventProperty
            mk_OverlayOpacity
            mk_OverlayOpacityColor {
              value
            }
            mk_picture {
              smSrcSet {
                asset {
                  url
                  altText
                }
              }
              lgSrcSet {
                asset {
                  altText
                  url
                }
              }
            }
            mk_sub_builder_array {
              ... on MkSubBody {
                mk_body
                mk_bodyColor {
                  value
                }
                _type
                _key
              }
              ... on MkSubBodyContentLove {
                mk_body
                mk_bodyColor {
                  value
                }
                _type
                _key
              }

              ... on MkSubCta {
                _key
                _type
                mk_callToAction
                mk_callToActionLink
                mk_callToActionImage {
                  asset {
                    url
                    altText
                  }
                }
                mk_callToActionLink
                _type
                _key
              }
              ... on MkSubHeaderLarge {
                _key
                _type
                mk_sub_header_large {
                  asset {
                    url
                    altText
                  }
                }
                _type
                _key
              }
              ... on MkSubCtaRectangle {
                _key
                _type
                mk_callToActionLink
                callToActionLinkAnalyticsEventName
                mk_callToAction_text
                _type
                _key
              }
              ... on MkSubCtaHtp {
                _key
                _type
                mk_callToActionImage {
                  asset {
                    url
                    altText
                  }
                }
                mk_callToActionLink
                mk_callToAction_text
                _type
                _key
              }
              ... on MkSubBodyHtp {
                _key
                _type
                mk_body
                mk_bodyColor {
                  value
                }
                _type
                _key
              }
              ... on MkSubHeader {
                mk_HeaderImage {
                  asset {
                    url
                    altText
                  }
                }
                mk_logoHeaderImage {
                  asset {
                    url
                    altText
                  }
                }
                _key
                _type
              }
            }
          }
        }
      }
      mk_seo {
        seoDescription
        seoTitle
        noIndex
        canonicalUrl
        seoKeywords
        openGraph {
          title
          type
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`)

export default getPage
