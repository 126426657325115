import { proximaNova } from '@/utils/fonts'
import styled from '@emotion/styled'

export const WhyFanDuelContainer = styled.div`
  background-color: white;
  padding-top: 30px;
  padding-bottom: 32px;
`

export const SectionHeaderContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
  h2 {
    font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
    font-size: 22px;
    font-weight: 700; // temp override of app.css styles
    margin-bottom: 20px;
  }
  p {
    font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
    line-height: 1.25;
  }
  @media (min-width: 640px) {
    text-align: center;
    margin-bottom: 60px;
    h2 {
      font-size: 32px;
    }
  }
`
// NOTE: update the styles for the heading and subtext and make responsive
export const WhyFanDuelTitle = styled.h2`
  font-size: 22px;
  text-align: left;
`

export const WhyFanDuelSubText = styled.p``
