import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const StyledImage = styled.img`
  margin: 0px;
  padding: 0px;

  border-style: none;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  width: 242px;
  height: auto;
  margin-bottom: 15px;
  font-family: '__Inter_83ccbe', '__Inter_Fallback_83ccbe';
`

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;

  ${mediaEmotion.sm(css`
    justify-content: flex-start;
  `)}
`
