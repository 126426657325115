import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const MkSubCTAStyled = styled.div`
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
  line-height: 18px;

  a {
    text-decoration: none;
    cursor: pointer;
    color: white;
    display: flex;
    padding-top: 5px;

    img {
      margin-left: 5px;
    }
  }

  ${mediaEmotion.sm(css`
    font-size: 14px;
  `)}

  ${mediaEmotion.lg(css`
    font-size: 18px;
    margin-bottom: 15px;
  `)}
`
