import { proximaNovaCondensed } from '@/utils/fonts'
import { ReactNode } from 'react'
import styles from './Select.module.scss'

type SelectProps = {
  id: string
  labelText?: string
  children: ReactNode
  handleOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const Select = ({ id, labelText, children, handleOnChange }: SelectProps) => {
  return (
    <div className={styles.container} data-testid="select-container">
      {labelText && (
        <label style={{ fontFamily: proximaNovaCondensed }} htmlFor={id} className={styles.label} data-testid="label">
          {labelText}
        </label>
      )}
      <select className={styles.select} onChange={handleOnChange} name={id} id={id} data-testid="select">
        {children}
      </select>
    </div>
  )
}

export default Select
