import { findByTestId } from '@testing-library/react'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
import usaMap from '../../../data/usaMap.json'
import styles from './Map.module.scss'
import { MAP_COLORS } from '@/constants/index'

type MapProps = { activeStates: (string | undefined)[] | undefined }
const Map = ({ activeStates }: MapProps) => {
  return (
    <div className={styles.container} data-testid="locations-map">
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={usaMap}>
          {({ geographies }) => (
            <>
              {geographies.map((geo, index) => {
                const stateCode = geo.properties.statecode

                const fillColor = activeStates?.includes(stateCode)
                  ? MAP_COLORS.AVAILABLE_FILL_COLOR
                  : MAP_COLORS.DEFAULT_FILL_COLOR

                return (
                  <Geography
                    key={index}
                    geography={geo}
                    data-testid={activeStates?.includes(stateCode) ? 'active-state' : 'inactive-state'}
                    style={{
                      default: {
                        fill: fillColor,
                        stroke: MAP_COLORS.STROKE_COLOR,
                        strokeWidth: 0.5,
                        outline: 'none',
                      },
                      hover: {
                        fill: fillColor,
                        stroke: MAP_COLORS.STROKE_COLOR,
                        strokeWidth: 0.5,
                        outline: 'none',
                      },
                      pressed: {
                        fill: fillColor,
                        stroke: MAP_COLORS.STROKE_COLOR,
                        strokeWidth: 0.5,
                        outline: 'none',
                      },
                    }}
                  />
                )
              })}
            </>
          )}
        </Geographies>
      </ComposableMap>
    </div>
  )
}

export default Map
