import { Icon } from '@fanduel/og-fe-library'
import styles from './WhereContent.module.scss'

type WhereContentProps = {
  numberOfStates: number
  currentProduct: string
  productDisclaimer?: string
  detailsLinkUrl?: string
}

const WhereContent = ({ numberOfStates, currentProduct, productDisclaimer, detailsLinkUrl }: WhereContentProps) => {
  return (
    <div className={styles.container} data-testid="where-content">
      <strong className={styles.info}>
        <span>
          FanDuel {currentProduct} is available in {numberOfStates} {numberOfStates === 1 ? 'state' : 'states'}.
          {currentProduct === 'Casino' && ' We also offer Casino in Canada.'}
        </span>
        {detailsLinkUrl && (
          <a className={styles.detailsLink} href={detailsLinkUrl}>
            View Details <Icon iconName="arrows.right" iconColor="contentLink" />
          </a>
        )}
      </strong>
      {productDisclaimer && (
        <p className={styles.disclaimer} data-testid="disclaimer">
          {productDisclaimer}
        </p>
      )}
    </div>
  )
}

export default WhereContent
