

import { graphql } from '../../gql/wrapper/gql';

export const getRewrites = graphql(`
query getRewrites($limit: Int, $offset: Int) {
  allMkRewrite(offset: $offset, limit: $limit, where: {_: {is_draft: false}}) {
    mk_old_path
  }
}
`);

export default getRewrites;

