import type { LocationsList, Maybe } from 'graphql/gql/wrapper/graphql'
import styles from './ProductLocationsList.module.scss'

type ProductLocationsListProps = { locations: Maybe<LocationsList>[]; currentProduct: string }

const ProductLocationsList = ({ locations, currentProduct }: ProductLocationsListProps) => {
  return (
    <div className={styles.container} data-testid="locations-list">
      <ul>
        {locations?.map((location, index) => (
          <li className={styles.location} key={`${index}${location}`} data-testid="location">
            <span>{currentProduct === 'Racing' && location?.tvgOnly ? `*${location?.name}` : location?.name}</span>
            {location?.subtext && <span data-testid="location-subtext">{location?.subtext}</span>}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProductLocationsList
