import Image from 'next/image'
import React from 'react'
import { MkSubCta as MkSubCtaGQL } from '../../../../graphql/gql/wrapper/graphql'
import { MkSubCTAStyled } from './styled/MkSubCTA.stlyed'

type MkSubCtaProps = {
  mk_SubCta: MkSubCtaGQL
}

const MkSubCta: React.FC<MkSubCtaProps> = ({ mk_SubCta }) => {
  return (
    <MkSubCTAStyled>
      <div>
        <a href={mk_SubCta?.mk_callToActionLink ?? ''}>
          {mk_SubCta?.mk_callToAction}
          <Image
            src={mk_SubCta?.mk_callToActionImage?.asset?.url ?? ''}
            alt={mk_SubCta?.mk_callToActionImage?.asset?.altText ?? 'Image'}
            width={18}
            height={18}
          />
        </a>
      </div>
    </MkSubCTAStyled>
  )
}

export default MkSubCta
