import { proximaNova } from '@/utils/fonts'
import styled from '@emotion/styled'

export const InfoCardContainer = styled.div`
  max-width: 100%;
  color: #131314;
  line-height: 115%;
  font-weight: 400;
  margin-bottom: 20px;
  h4 {
    font-family: ${proximaNova}, sans-serif; // temp override of app.css styles
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  a {
    text-decoration: none;
    color: #005fc8;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 640px) {
    padding: 0 10px;
  }
`
