import { ImageProps } from '@/types/cards'
import { mediaEmotion } from '@/utils/general'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Image from 'next/image'


export const ExploreItemHeadingStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  ${mediaEmotion.lg(css`
    margin-bottom: 18px;
  `)}
`

const BadgeWrapper = styled.div`
  margin: 0 8px 0 0;
  vertical-align: middle;
  transition: opacity 0.3s ease;
  opacity: 1;
  display: inline-block;
  height: auto;

  ${mediaEmotion.sm(css`
    margin: 0 8px 0 0;
    border: none;
    vertical-align: middle;
    max-width: 100%;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    display: inline-block;
    height: auto;
  `)}

  ${mediaEmotion.lg(css`
    margin: 0 8px 0 0;
    border: none;
    vertical-align: middle;
    max-width: 100%;
    transition: opacity 0.3s ease 0s;
    opacity: 1;
    display: inline-block;
    width: 24px;
    height: auto;
  `)}
`


export const BadgeImage: React.FC<ImageProps> = ({ src, alt }) => (
  <BadgeWrapper>
    <Image src={src} alt={alt} width={24} height={24} />
  </BadgeWrapper>
)

const StyledHeaderImage = styled.img`
  transition: opacity 0.3s ease;
  width: auto;
  height: 16px;

  ${mediaEmotion.sm(css`
    height: 10px;
  `)}

  ${mediaEmotion.lg(css`
    height: 16px;
  `)}
`


export const HeaderImage: React.FC<ImageProps> = ({ src, alt }) => (
  <StyledHeaderImage src={src} alt={alt} />
)
