import { getMenus } from '@/utils/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { Menu } from '../../../../graphql/gql/orch/graphql';

type MenuResponse = {
  menuData: Record<string, Menu | undefined>,
  isError: boolean,
  isLoading: boolean,
};

const useMenus = (menus: string[]): MenuResponse => {
  const { data, isError, isLoading } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['menus', menus],
    queryFn: () => getMenus(),
    select: (response) => response.getMenus || [],
  });

  const menuData = useMemo(() => (data ? menus.reduce(
    (menuCollection, menu) => {
      const test = data.find(
        (menuInfo) => menuInfo?.name?.toLowerCase() === menu.toLocaleLowerCase(),
      ) || undefined;

      return ({
        ...menuCollection,
        [menu]: test,
      });
    },
    {} as Record<string, Menu | undefined>,
  ) : {}), [data, menus]);

  return {
    menuData,
    isError,
    isLoading,
  };
};

export const useNavMenus = () => useMenus([ 'footer', 'terms', 'copyright']);

export default useMenus;
