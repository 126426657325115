import { graphql } from '../../gql/wrapper/gql';

export const getPages = graphql(`
query getPages($limit: Int, $offset: Int) {
  allMkMarketingPage(offset: $offset, limit: $limit, where: {_: {is_draft: false}})
   {
    _id
    mk_slug {
      current
    }
  }
}
`);

export default getPages;
