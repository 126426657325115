import { NextSeo } from "next-seo";
import { SEO } from "../../constants";
import { SinglePageTypeSEO } from "../../pages";

export type SEOType = {
  slug:  string
  mk_seo: SinglePageTypeSEO;
};

type SEOComponentProps = {
  seoData: SEOType;
};

const SEOComponent: React.FC<SEOComponentProps> = ({ seoData }) => {
  const { mk_seo ,slug} = seoData;

  const openGraphTitle = mk_seo?.openGraph?.title ?? mk_seo?.seoTitle
  const image = mk_seo?.openGraph?.image?.asset?.url;
  const images = image
    ? {
        images: [
          {
            width: 800,
            height: 600,
            alt: "Og Image Alt",
            type: "image/jpeg",
            url: image,
          },
        ],
      }
    : {};

  return (
    <div data-testid="seo-component">
      <NextSeo
        title={mk_seo?.seoTitle ?? ""}
        description={mk_seo?.seoDescription ?? ""}
        canonical={mk_seo?.canonicalUrl ? mk_seo?.canonicalUrl  : `${ SEO.FANDUEL_URL}${slug}` }

        additionalMetaTags={[{
          name: 'keywords',
          content: mk_seo?.seoKeywords ?? ''
        }]}

        noindex={mk_seo?.noIndex ? true : false }
        nofollow={mk_seo?.noIndex ? true : false }
        openGraph={{
          title: openGraphTitle ?? "",
          url: mk_seo?.canonicalUrl ? mk_seo?.canonicalUrl  : `${ SEO.FANDUEL_URL}${slug}`,
          description: mk_seo?.seoDescription ?? "",
          ...images,
        }}
      />
    </div>
  );
};

export default SEOComponent;
