import styled from '@emotion/styled'

export const StyledParagraph = styled.p`
  color: rgb(255, 255, 255);
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  width: 90%;
`
