import DOMPurify from 'isomorphic-dompurify'
import parse from 'html-react-parser'
import { InfoCardContainer } from './InfoCard.styled'

type InfoCardProps = {
  title: string
  content: string
}

const sanitizeHtml = (html: string) => DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })

const InfoCard = ({ title, content }: InfoCardProps) => {
  return (
    <InfoCardContainer data-testid="info-card-container">
      <h4>{title}</h4>
      {parse(sanitizeHtml(content))}
    </InfoCardContainer>
  )
}

export default InfoCard
