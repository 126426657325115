import React from 'react'
import { MkCustomJsTemplate } from '../../../graphql/gql/wrapper/graphql'

type CustomJSProps = {
  mk_customJs: MkCustomJsTemplate
}

export default function CustomJS({ mk_customJs }: Readonly<CustomJSProps>) {
  const { mk_js } = mk_customJs
  return <div lang="en" dangerouslySetInnerHTML={{ __html: mk_js as string }} />
}
