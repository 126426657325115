import {  CrossSellHeader, LoginHeader } from "@fanduel/og-fe-library";

function DfsHeader() {
  return (
    <div style={{ position: 'sticky', top: 0, zIndex: 200 }} data-testid="dfs-header">
 <CrossSellHeader tabs={[


  {
    "text": "Sportsbook",
    "href": "https://sportsbook.fanduel.com/",
    "openInNewWindow": false,
  },
  {
    "text": "Fantasy",
    "href": "https://fanduel.com",
    "openInNewWindow": false,
    "isActive": true
  },
  {
    "text": "Racebook",
    "href": "https://racing.fanduel.com/",
    "openInNewWindow": true
  },
  {
    "text": "CASINO",
    "href": "https://casino.fanduel.com/",
    "openInNewWindow": true
  }
]} theme="Fantasy"  />
      <LoginHeader product="fantasy" loginURL="/login" signupURL='/join'  />
    </div>
  );
}

export default DfsHeader;
